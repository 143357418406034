var $ = require('jquery');
require('jquery.scrollto');
require('smartmenus');
require('smartmenus-keyboard');
require('slick-carousel');


$(function() {
  var $window        = $(window),
      $body          = (window.opera) ? (document.compatMode == "CSS1Compat" ? $('html') : $('body')) : $('html,body'),
      $humberWidth   = 1366,
      $mainMenuState = $('.main-menu-state'),
      $Navi          = $('.Navi'),
      $Header        = $('.Header'),
      $menu          = $('.main-menu'),
      $wrapper       = $('.Wrapper'),
      $Search        = $('.Search'),
      $searchBtn     = $('.search-button'),
      $SideProducts  = $('.SideProducts .product-menu'),
      $banner        = $('.Banner .slick'),
      $HotProducts   = $('.HotProducts .slick'),
      $panel         = $('.js-panel'),
      $gototopBTN    = $('.gototop'),
      $windowScroll  = 0,
      $lastScroll    = 0,
      $scrollUpStart = 0;

  /* -------------------
        smartmenus
  ------------------- */
  /* :: smartmenus menu :: */
  if ($menu.length) {
    $menu.smartmenus({
      subMenusSubOffsetX: 0,
      subMenusSubOffsetY: 0,
      markCurrentItem: true,
      arrowClicked: true,
      collapsibleShowFunction: function($ul, complete) {
        $ul.slideDown(200, function() {
          complete();
          $menu.scrollTo($ul.dataSM('parent-a'), 500);
        });
      }
    });
  }

  if ($mainMenuState.length) {
    $mainMenuState.insertBefore('.Wrapper').on({
      change: function(e) {
        if (this.checked) {
          $body.addClass('main-menu-open');
        } else {
          $body.removeClass('main-menu-open');
        }
      }
    });
  }

  if ($searchBtn.length) {
    $searchBtn.on({
      click: function(){
        if (window.innerWidth > $humberWidth) {
          $Search.addClass('Search-open');
        } else {
          $('#searchbox_').submit();
        }
      }
    });
  }

  $(document).mouseup(function (event){
    if ($mainMenuState.length && $mainMenuState.is(':checked') && $wrapper.is(event.target)) {
      $mainMenuState.click();
    }

    if (window.innerWidth > $humberWidth && $Search.length && !$('.Search input').is(event.target)) {
      $Search.removeClass('Search-open');
    }
  });

  /* :: smartmenus SideProducts :: */
  if ($SideProducts.length) {
    $SideProducts.addClass('sm').smartmenus({
      subMenusSubOffsetX: 0,
      subMenusSubOffsetY: 0,
      markCurrentItem: true,
      arrowClicked: true,
      collapsibleShowFunction: function($ul, complete) {
        $ul.slideDown(200, function() {
          complete();
          $SideProducts.scrollTo($ul.dataSM('parent-a'), 500);
        });
      }
    });
  }


  /* -------------------
        slick
  ------------------- */
  /* :: slick banner :: */
  if ($banner.length) {
    $banner.slick({
      dots: true,
      fade: true,
      useCSS: true,
      slidesToShow: 1,
    });
  }

  /* :: slick HotProducts :: */
  if ($HotProducts.length) {
      $HotProducts.slick({
      autoplay: true,
      lazyLoad: 'ondemand',
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }


  /* -------------------
    Modules
  ------------------- */

  // panel
  $panel.find('.panel-body').slideUp('fast').addClass('panel-body--close');
  $panel.find('.panel-head').click(function(event) {
    $panelBody = $(this).next();
    if ($panelBody.hasClass('panel-body--close')){
      $panelBody
        .slideDown('400')
        .removeClass('panel-body--close')
        .addClass('panel-body--open');
    } else {
      $panelBody
        .slideUp('400')
        .removeClass('panel-body--open')
        .addClass('panel-body--close');
    }
  });


  /* -------------------
    Mobile Device Title
  ------------------- */
  var $brandColor = $searchBtn.css("background-color");
  $('meta[name=theme-color], meta[name=theme-color], meta[apple-mobile-web-app-status-bar-style]').attr('content', $brandColor);


  /* -------------------
      Scroll Event
  ------------------- */
  $gototopBTN.hide();

  $(window).scroll(function() {
    $windowScroll = $(window).scrollTop();

    if ($windowScroll <= $Header.height()) {
      $Navi.removeClass('fixed');

      if (window.innerWidth > $humberWidth) {
        $Header.css('margin-bottom', 0);
      }

    } else if ($lastScroll > $windowScroll) {

      if ($scrollUpStart < $windowScroll) {
        $scrollUpStart = $windowScroll;
      }

      if ($scrollUpStart - $windowScroll > 100) {

        if (window.innerWidth > $humberWidth) {
          $Header.css('margin-bottom', $Navi.height());
        }

        $Navi.addClass('fixed');
      }
    } else {
      $scrollUpStart = 0;
      $Navi.removeClass('fixed');

      if (window.innerWidth > $humberWidth) {
        $Header.css('margin-bottom', 0);
      }
    }

    $lastScroll = $windowScroll;


    if($windowScroll <= 400) {
      if ($gototopBTN.length) { $gototopBTN.hide(); }
    } else {
      if ($gototopBTN.length) { $gototopBTN.show(); }
    }
  });

  $gototopBTN.click(function(e){
    $body.animate({ scrollTop: 0 }, 600);
    $gototopBTN.blur();
  }).scroll();

});
